<template>
  <div class="">
    <nav class="navbar navbar-custom">
      <div class="container">
        <div>
          <img
            src="@/assets/img/_ui/svg/text-resize.svg"
            alt="resize text" srcset="" role="button" tabindex="0"
            @click="resizeText()"
          >
        </div>
        <!-- <a class="navbar-brand" href="#">Your students’ resilience</a> -->
        <router-link to="/"><img src="@/assets/img/_ui/svg/home.svg" alt="home" height="30px" srcset=""></router-link>
      </div>
    </nav>

    <div class="bg-img bg-img--bg-01 pt-5">
      <div class="container">
        <!-- CONTENT -->
        <h1 class="text-orange">The problem with “resilience”</h1>
        <p>Resilience is a popular concept within the realm of student success. But no concept is entirely perfect as is. It is important for us to examine and problematize the concepts we use to support student success. Let’s take a moment to problematize some of the common pillars of resilience in order to improve our understanding of the concept.</p>
      </div>

      <div class="tree-bg">
        <div class="container p-4 mt-5">
          <h2 class="text-orange mb-4 text-center">Resilience maintains the status quo </h2>
          <p>Resilience work on campus often focuses on giving students the skills and supports to return to what is considered normal, or baseline, within our post-secondary education system, after they’ve gone through a difficult experience.</p>
          <p> When supporting students in this return to normal/baseline, there are a few things we should question.</p>
        </div>
      </div>

      <div class="container p-4">
        <!-- CONTENT -->
        <div class="row align-items-center">
          <div class="col-md-6">
            <h3>What is the aim of the resilience programming, tools or other resources that we are enacting? </h3>
            <p>Are we hoping that circumstances will stay the same for students or do we want their circumstances to improve? It is important to consider if our current normal/baseline is what we really want to maintain (Cai, 2020). We must also question if it is fair to ask students to put in the work of changing or growing only to then have to work within the same system that may not have had adequate supports (Prowell, 2019; Moulton and Machado, 2019). When we engage in resilience work on campus, one of our aims should be growth, both for our students and our campuses more broadly (Moulton and Machado, 2019). </p>
          </div>
          <div class="col-md-6">
            <img src="@/assets/img/_quotes/centennial-tic-resilience-quote1.svg" class="mx-auto d-block" alt="When we engage in resilience work on campus, one of our aims should be growth" srcset="">
          </div>

          <div class="col-md-6 order-md-2">
            <h3>Are we being cognizant of the language we use in our work with students on resilience? </h3>
            <p>Our language can often help to maintain the status quo of our current system and impede change (Taylor, 2018). Whenever we can, we should attempt to make the effort to ensure the language we do use does not contribute to slowing down the movement towards systemic change on our campuses. An example of this language would be a focus on “deficits” within individuals as opposed to structural issues within our campus systems (Sehgal, 2015). Resilience work should make space for us to question our systems (Sehgal, 2015). Resilience tools, like this toolkit, are a chance to introduce new language that can <strong>shift conversations</strong> and <strong>enable systemic change</strong>.</p>
          </div>
          <div class="col-md-6">
            <img src="@/assets/img/_quotes/centennial-tic-resilience-quote2.svg" class="mx-auto d-block" alt="" srcset="">
          </div>
        </div>
      </div>
    </div>

    <!--  -->
    <div class="bg-img bg-img--bg-01 pt-5">
      <div class="tree-bg">
        <div class="container p-4 mt-5">
          <h2 class="text-orange mb-4 text-center">Resilience puts the onus on individuals to change</h2>
          <p>Resilience work in the post-secondary context has tended to focus on individual change.</p>
          <p>This focus puts the onus on students engaging with system to do the hard work of change, as opposed to having the system undergo significant change in tandem with students.</p>
          <p>Here are a few things to consider when it comes to systemic change and the post-secondary context.</p>
        </div>
      </div>

      <div class="container p-4">
        <!-- CONTENT -->
        <div class="row align-items-center">
          <div class="col-md-6">
            <h3>The resilience work we engage in should be plugging into work on systemic change</h3>
            <p>The work should be specific to the post-secondary context because the context that we live and work in impacts our ability to both develop and apply resilience (Matlin, Forrester & Ensor, 2018). Nothing is ever truly neutral (Fletcher & Sarkar, 2013). People and the systems they live in are interconnected (see the <a href="https://www.researchgate.net/figure/Socio-ecological-model-framework-for-prevention-centers-for-disease-control-Available_fig1_318840832" target="_blank">socio ecological model</a>). So, understanding and adapting our resilience work to its context using tools like the TiC toolkit can help us to engage in systemic change while we also work to support student resilience in the campus context.</p>
          </div>
          <div class="col-md-6">
            <img src="@/assets/img/_quotes/centennial-tic-resilience-quote3.svg" class="mx-auto d-block" alt="When we engage in resilience work on campus, one of our aims should be growth" srcset="">
          </div>

          <div class="col-md-6 order-md-2">
            <h3>Internal and external factors contribute to resilience</h3>
            <p>We must understand that there are internal (personal) and external (environmental) factors that contribute to resilience and that they work together (Taylor, 2018; Ungar, 2019). We cannot solely position resilience work in individual change. It also needs to be positioned in the environment. We must always ask two questions when we do this work: </p>
            <ul>
              <li><em>How can students support themselves in order to thrive?</em></li>
              <li><em>How can we challenge/change the environment to enable students to thrive?</em></li>
              <li style="list-style: none;">(Fletcher & Sarkar, 2013)</li>
            </ul>
          </div>
          <div class="col-md-6">
            <img src="@/assets/img/_quotes/centennial-tic-resilience-quote4.svg" class="mx-auto d-block" alt="" srcset="">
          </div>
        </div>

        <p>Within some resilience frameworks, certain populations are often positioned as having a higher likelihood of being less resilient, and this risk is centered around their identity. This way of thinking <strong>discounts the systems students live in</strong>. We must recall how these risks were created in the first place, most often from our systems designating people with certain intersections (e.g., racialized, disabled, queer) as “the other” (Moulton & Machado, 2019; Cai, 2020). </p>
      </div>
    </div>

    <!--  -->
    <div class="bg-img bg-img--bg-01 pt-5">
      <div class="tree-bg">
        <div class="container p-4 mt-5">
          <h2 class="text-orange mb-4 text-center">Resilience discounts power dynamics</h2>
          <p>Much of the resilience work in post-secondary institutions is positioned as staff enabling students to become resilient through teaching students certain skills. This assumes that staff are the only ones with knowledge to bring to the table, but that is not the case (Prowell, 2019).  </p>
        </div>
      </div>

      <div class="container p-4">
        <!-- CONTENT -->
        <div class="row align-items-center">
          <div class="col-md-6 order-md-2">
            <img src="@/assets/img/_quotes/centennial-tic-resilience-quote5.svg" class="mx-auto d-block" alt="When we engage in resilience work on campus, one of our aims should be growth" srcset="">
          </div>
          <div class="col-md-6">
            <p>When considering resilience and power dynamics we should think about the following:</p>
            <ul>
              <li>Resilience should be positioned as collective learning, growth and empowerment instead of simply teaching skills to students or fixing them up (Leonard, 2020; Moulton & Machado, 2019).  This shift in thinking helps us to see that everyone has something to learn while we engage in the practice of resilience on campus.</li>
              <li>The language we use and discourses we have around resilience have power. Language shapes our identities and the types of discussions we are willing to have (Prowell, 2019). If we can start to shift and open ourselves up to using new language, we can start to have new and insightful discussions about systemic change on campus. </li>
              <li>In resilience work, the balance of power lies in the system because of the impact it has on individuals and the personal constraints it can create for them via interactions with campus environment (Ungar, 2019). Though students (and staff and faculty) have the ability to make change, what they can do is intrinsically linked to the kind of campus environment they’re in.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!--  -->
    <div class="bg-img bg-img--bg-01 pt-5">
      <div class="tree-bg">
        <div class="container p-4 mt-5">
          <h2 class="text-orange mb-4 text-center">“Resilience” and “grit” often go hand in hand</h2>
          <p>Grit is a very prominent piece of resilience work. One of the most noted authorities in this area is Angela Duckworth, whose definition of grit positions it as “perseverance and passion for long term goals” (Duckworth, 2016). This definition is pervasive in resilience work, but it is one we should question as we do resilience work with students on campus.</p>
        </div>
      </div>

      <div class="container p-4">
        <!-- CONTENT -->
        <div class="row align-items-center">
          <div class="col-md-6 order-md-2">
            <img src="@/assets/img/_quotes/centennial-tic-resilience-quote6.svg" class="mx-auto d-block" alt="When we engage in resilience work on campus, one of our aims should be growth" srcset="">
          </div>
          <div class="col-md-6">
            <p>Here are some reasons why you should consider questioning the concept of grit:</p>
            <ul>
              <li>This particular definition (and others) position grit as a motivational drive. This <strong>does not make space for or situate grit within students’ social context</strong> (Denby, 2016). Instead, it positions grit as something you build by digging down and pulling yourself up by the bootstraps through willpower. This is not very helpful for students because this view assumes that all students have the resources needed to do this and are also in an environment that enables them to that work.</li>
              <li>Grit takes a very individualistic approach to student success. This approach can lead to the overshadowing of the work faculty and staff do to support students (Shreiner, 2017). Your work helps to create an environment that enables students to succeed is incredibly important, but grit’s narrow focus <strong>does not always allow for discussion of how it can contribute to a campus environment that supports student resilience.</strong> </li>
              <li>Much of the framing of grit is very <strong>centered on the perceived deficits within students</strong>, and how they contribute to a perceived lack of success, as opposed to focusing on the skills they do have and building upwards from there (Shreiner, 2017). This focus on deficits can obscure the part a campus system plays in creating or exacerbating these perceived deficits that students may experience.</li>
            </ul>
          </div>
        </div>
        <p>In the post-secondary context, resilience is an amazing tool that we can use to support both the growth of our students and our own growth as well. But as we engage in this work, it is important to both continually think critically about why we are doing resilience work and questions the concept itself and how we work with it.</p>
      </div>
    </div>

    <!--  -->
    <div class="bg-img bg-img--bg-01 pt-5">
      <div class="tree-bg">
        <div class="container p-4 mt-5">
          <div class="row justify-content-center">
            <div class="col-sm-10 col-md-8 col-lg-5">
              <router-link to="/" class="btn-nav btn-nav--orange text-center">
                Bring me back to the Toolkit
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'
// @ is an alias to /src

export default {
  name: 'Home',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },

  data () {
    return {
      currentFontSize: 100,
      isSidebarActive: true,
      isSidebarToggled: true
    }
  },

  methods: {
    resizeText () {
      const body = document.getElementsByTagName('html')[0]
      this.currentFontSize += 20
      if (this.currentFontSize >= 160) {
        this.currentFontSize = 100
      }

      body.style.fontSize = `${this.currentFontSize}%`
    },

    toggleSidebar () {
      this.isSidebarToggled = !this.isSidebarToggled

      if (!this.isSidebarToggled) {
        document.getElementsByClassName('sidebar-menu')[0].className += ' d-none'
      } else {
        document.getElementsByClassName('sidebar-menu')[0].className = 'sidebar-menu'
      }
    }
  },

  computed: {
    // isSidebarActive () {
    //   console.log(document.getElementsByClassName('sidebar-menu').length)
    //   if (document.getElementsByClassName('sidebar-menu').length >= 1) {
    //     return true
    //   } else {
    //     return false
    //   }
    // }
  },

  updated () {
    console.log(document.getElementsByClassName('sidebar-menu').length)
    if (document.getElementsByClassName('sidebar-menu').length >= 1) {
      this.isSidebarActive = true
    } else {
      this.isSidebarActive = false
    }
  },

  mounted () {
  }
}
</script>

<style lang="scss" scoped>

  h2 {
    color: #D96704;
  }

  h3 {
    color: #117105;
  }

  strong {
    color: #117105;
  }

  a {
    color: #D96704;
  }

  .text-orange {
    color: #D96704;
  }

  .bg-img {
    &--bg-01 {
      background-image: url('../assets/img/_bg/mbl - main background image.jpg');
      background-attachment: fixed;
      background-size: contain;
      background-position: top;

      @media (min-width: 768px) {
        background-image: url('../assets/img/_bg/dsk - main background image.jpg');
      }
    }
  }

  .tree-bg {
    background-color: #171E27;
    color: #fff;
    position: relative;
    margin-top: 5rem;

    &::before {
      content: '';
      background-image: url('../assets/img/_bg/mbl - divider.png');
      background-size: contain;
      left: 0;
      top: -5rem;
      width: 100%;
      height: 5rem;
      position: absolute;

      @media (min-width: 768px) {
        background-image: url('../assets/img/_bg/dsk - divider.png');
      }
    }
  }
</style>
