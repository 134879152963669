<template>
  <div class="students-resilience">
    <nav class="navbar navbar-custom">
      <div class="container">
        <div>
          <img
            src="@/assets/img/_ui/svg/text-resize.svg"
            class="me-3"
            alt="resize text" srcset="" role="button" tabindex="0"
            @click="resizeText()"
          >
          <img
            src="@/assets/img/_ui/svg/hamburger-open.svg"
            class="d-md-none"
            alt="menu" srcset="" role="button" tabindex="0"
            height="15px"
            v-if="isSidebarActive"
            @click="toggleSidebar()"
          >
        </div>
        <router-link to="/" class="navbar-brand">Thriving in the Classroom</router-link>
        <router-link to="/"><img src="@/assets/img/_ui/svg/home.svg" alt="home" height="30px" srcset=""></router-link>
      </div>
    </nav>
    <!-- <transition
      mode="out-in"
      appear
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
    > -->
    <transition>
      <router-view/>
    </transition>
  </div>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'
// @ is an alias to /src

export default {
  name: 'Home',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },

  data () {
    return {
      currentFontSize: 100,
      isSidebarActive: true,
      isSidebarToggled: true
    }
  },

  methods: {
    resizeText () {
      const body = document.getElementsByTagName('html')[0]
      this.currentFontSize += 20
      if (this.currentFontSize >= 160) {
        this.currentFontSize = 100
      }

      body.style.fontSize = `${this.currentFontSize}%`
    },

    toggleSidebar () {
      this.isSidebarToggled = !this.isSidebarToggled

      if (!this.isSidebarToggled) {
        document.getElementsByClassName('sidebar-menu')[0].className += ' d-none'
      } else {
        document.getElementsByClassName('sidebar-menu')[0].className = 'sidebar-menu'
      }
    }
  },

  computed: {
    // isSidebarActive () {
    //   console.log(document.getElementsByClassName('sidebar-menu').length)
    //   if (document.getElementsByClassName('sidebar-menu').length >= 1) {
    //     return true
    //   } else {
    //     return false
    //   }
    // }
  },

  updated () {
    console.log(document.getElementsByClassName('sidebar-menu').length)
    if (document.getElementsByClassName('sidebar-menu').length >= 1) {
      this.isSidebarActive = true
    } else {
      this.isSidebarActive = false
    }
  },

  mounted () {
  }
}
</script>
